let base64 = document.getElementById('base64');
let base64Text = document.getElementById('base64Text');

if (base64) {
    base64.addEventListener('input', (event) => {
        try {
            base64Text.value = Buffer.from(base64.value).toString('base64');
        } catch (error) {
            base64Text.value = error;
        }
    });
}

if (base64Text) {
    base64Text.addEventListener('input', (event) => {
        try {
            base64.value = Buffer.from(base64Text.value, 'base64').toString();
        } catch (error) {
            base64.value = error;
        }
    });
}
