let timeTimestamp = document.getElementById('timeTimestamp');
let timeYear = document.getElementById('timeYear');
let timeMonth = document.getElementById('timeMonth');
let timeDay = document.getElementById('timeDay');
let timeHour = document.getElementById('timeHour');
let timeMinutes = document.getElementById('timeMinutes');
let timeSeconds = document.getElementById('timeSeconds');

if (timeTimestamp) {
    timeTimestamp.onkeydown = testNumber;
    timeTimestamp.addEventListener('input', timestamp2Time);
    timeTimestamp.onfocus = function() {
        this.select();
    }
}

if (timeYear) {
    timeYear.onkeydown = testNumber;
    timeYear.addEventListener('input', time2Timestamp);
}

if (timeMonth) {
    timeMonth.onkeydown = testNumber;
    timeMonth.addEventListener('input', time2Timestamp);
}

if (timeDay) {
    timeDay.onkeydown = testNumber;
    timeDay.addEventListener('input', time2Timestamp);
}

if (timeHour) {
    timeHour.onkeydown = testNumber;
    timeHour.addEventListener('input', time2Timestamp);
}


if (timeMinutes) {
    timeMinutes.onkeydown = testNumber;
    timeMinutes.addEventListener('input', time2Timestamp);
}

if (timeSeconds) {
    timeSeconds.onkeydown = testNumber;
    timeSeconds.addEventListener('input', time2Timestamp);
}

function timestamp2Time() {
    let timestampMax = timeTimestamp.getAttribute('max');
    let timestampMin = timeTimestamp.getAttribute('min');
    let timestampValueInt = parseInt(timeTimestamp.value);

    if (timestampMax !== null && timestampValueInt > parseInt(timestampMax)) {
        timeTimestamp.value = timestampMax;
    } else if (timestampMin !== null && timestampValueInt < parseInt(timestampMin)) {
        timeTimestamp.value = timestampMin;
    }

    let date = new Date(timeTimestamp.value * 1000);

    timeYear.value = date.getFullYear();
    timeMonth.value = date.getMonth() + 1;
    timeDay.value = date.getDate();
    timeHour.value = date.getHours();
    timeMinutes.value = date.getMinutes();
    timeSeconds.value = date.getSeconds();
}

function time2Timestamp() {
    let yearMax = timeYear.getAttribute('max');
    let yearMin = timeYear.getAttribute('min');
    let yearValueInt = parseInt(timeYear.value);

    let monthMax = timeMonth.getAttribute('max');
    let monthMin = timeMonth.getAttribute('min');
    let monthValueInt = parseInt(timeMonth.value);

    let dayMax = timeDay.getAttribute('max');
    let dayMin = timeDay.getAttribute('min');
    let dayValueInt = parseInt(timeDay.value);

    let hourMax = timeHour.getAttribute('max');
    let hourMin = timeHour.getAttribute('min');
    let hourValueInt = parseInt(timeHour.value);

    let minutesMax = timeMinutes.getAttribute('max');
    let minutesMin = timeMinutes.getAttribute('min');
    let minutesValueInt = parseInt(timeMinutes.value);

    let secondsMax = timeSeconds.getAttribute('max');
    let secondsMin = timeSeconds.getAttribute('min');
    let secondsValueInt = parseInt(timeSeconds.value);

    if (yearMax !== null && yearValueInt > parseInt(yearMax)) {
        timeYear.value = yearMax;
    } else if (yearMin !== null && yearValueInt < parseInt(yearMin)) {
        timeYear.value = yearMin;
    }

    if (monthMax !== null && monthValueInt > parseInt(monthMax)) {
        timeMonth.value = monthMax;
    } else if (monthMin !== null && monthValueInt < parseInt(monthMin)) {
        timeMonth.value = monthMin;
    }

    if (dayMax !== null && dayValueInt > parseInt(dayMax)) {
        timeDay.value = dayMax;
    } else if (dayMin !== null && dayValueInt < parseInt(dayMin)) {
        timeDay.value = dayMin;
    }

    if (hourMax !== null && hourValueInt > parseInt(hourMax)) {
        timeHour.value = hourMax;
    } else if (hourMin !== null && hourValueInt < parseInt(hourMin)) {
        timeHour.value = hourMin;
    }

    if (minutesMax !== null && minutesValueInt > parseInt(minutesMax)) {
        timeMinutes.value = minutesMax;
    } else if (minutesMin !== null && minutesValueInt < parseInt(minutesMin)) {
        timeMinutes.value = minutesMin;
    }

    if (secondsMax !== null && secondsValueInt > parseInt(secondsMax)) {
        timeSeconds.value = secondsMax;
    } else if (secondsMin !== null && secondsValueInt < parseInt(secondsMin)) {
        timeSeconds.value = secondsMin;
    }

    timeTimestamp.value = (new Date(timeYear.value, timeMonth.value - 1, timeDay.value, timeHour.value, timeMinutes.value, timeSeconds.value)).getTime() / 1000;
}

function testNumber(e) {
    if(!((e.keyCode > 95 && e.keyCode < 106)
      || (e.keyCode > 47 && e.keyCode < 58)
      || e.keyCode == 8
      || e.keyCode == 67
      || e.keyCode == 86
      || e.keyCode == 88
      || e.keyCode == 90
      || e.keyCode == 65
      || e.keyCode == 116)) {
        return false;
    }
}
