let generateBtn = document.getElementById('generateBtn');
let pwdResult = document.getElementById('pwdResult');

let arrNum = [1, 2, 3, 4, 5, 6, 7, 8, 9];
let arrEnLower = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
let arrEnUpper = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
let arrSymb = ['!', '@', '#', '$', '%', '?', '-', '+', '=', '~'];
let arrNumSame = [0];
let arrEnLowerSame = ['o'];
let arrEnUpperSame = ['O'];

const compareRandom = () => Math.random() - 0.5;

const randomInteger = ( min, max ) => Math.round(min - 0.5 + Math.random() * (max - min + 1));

function generatePassword() {
    let arr = [];

    let generateFrom = document.getElementById('generateFrom').value;

    if (generateFrom) {
        arr = generateFrom.split('');
    } else {
        if (document.getElementById('arrNum').checked) arr = arr.concat(arrNum);
        if (document.getElementById('arrNum').checked && !document.getElementById('arrSame').checked) arr = arr.concat(arrNumSame);
        if (document.getElementById('arrEnLower').checked) arr = arr.concat(arrEnLower);
        if (document.getElementById('arrEnLower').checked && !document.getElementById('arrSame').checked) arr = arr.concat(arrEnLowerSame);
        if (document.getElementById('arrEnUpper').checked) arr = arr.concat(arrEnUpper);
        if (document.getElementById('arrEnUpper').checked && !document.getElementById('arrSame').checked) arr = arr.concat(arrEnUpperSame);
        if (document.getElementById('arrSymb').checked) arr = arr.concat(arrSymb);
    }

    arr.sort(compareRandom);

    let passLenght = document.querySelector('#passLenght').value;

    let html = '';

    for (j = 0; j < 15; j++) {
        let password = '';

        for (let i = 0; i < passLenght; i++) {
            password += arr[randomInteger(0, arr.length - 1)];
        }

        html += '<span onclick="toClipboard(this);">' + password + '</span><br>';
    }

    pwdResult.innerHTML = html;
}

toClipboard = function (_this) {
    let pwdBadge = document.getElementById("pwd-badge");

    if (pwdBadge) {
        pwdBadge.remove();
    }

    navigator.clipboard.writeText(_this.textContent).then(function() {
        _this.innerHTML = _this.textContent + '<span class="badge bg-primary" id="pwd-badge">Copy</span>';
    }, function(err) {
        console.error('Could not copy text: ', err);
    });
}

if (generateBtn) {
    generateBtn.addEventListener('click', generatePassword);
}
